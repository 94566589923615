.informationWindow {
  position: fixed;
  bottom: 15px;
  background-color: #fff;
  padding: 12px 20px;
  right: 15px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 10px #000;
  .title{
    font-size: 20px;
    color: #f63f3f;
  }
  .understandButton{
    margin-top: 8px;
    padding: 10px 0;
    border: 1px solid #f63f3f;
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    transition: 0.4s;
    cursor: pointer;
    &:hover{
      background-color: #f63f3f;
      color: #fff;
    }
  }
}