.windowPrivacyPolicy{
  position: fixed;
  bottom: 15px;
  background-color: #fff;
  padding: 12px 20px;
  right: 15px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 10px #000;
  .title{
    color: #f63f3f;
  }
  .text{
    max-width: 500px;
  }
  .acceptButton{
    background-color: #f63f3f;
    color: #fff;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
      background-color: #f86666;
    }
  }
}