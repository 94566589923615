.container {
  max-width: 1276px;
  margin: 0 auto;
}

.contact{
  padding: 130px 25px 0 25px;
  margin-top: 20px;
  .contactTitleWrapper{
    display: flex;
    justify-content: center;
    .contactTitle{
      padding-bottom: 5px;
      font-size: 36px;
      border-bottom: 4px solid #f63f3f;
      text-align: center;
    }
  }
  .contactForm{
    .backLink{
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000;
      text-decoration: none;
      font-size: 22px;
      font-weight: 300;
      .backIcon{
        transition: 0.3s;
        margin-right: 4px;
      }
      &:hover{
        .backIcon{
          color: #f63f3f;
        }
      }
    }
  }
}

@media(max-width: 800px){
  .contact{
    .contactInformation{
      .content{
        .list{
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}