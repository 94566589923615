.container{
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 30px;
}

.aboutUs{
  padding-top: 150px;
  .titleWrapper{
    display: flex;
    justify-content: center;
    .title{
      padding-bottom: 5px;
      font-size: 36px;
      border-bottom: 4px solid #f63f3f;
      text-align: center;
    }
  }
  .text{
    margin: 20px 0;
    font-size: 18px;
    span{
      font-weight: bold;
    }
  }
  .list{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    .item{
      border-radius: 20px;
      box-shadow: 0 0 10px #000;
      background-color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 50px;
      .title{
        margin-bottom: 8px;
        text-align: center;
        font-size: 22px;
        color: #f63f3f;
        font-weight: bold;
      }
      .itemText{
        color: #fff;
        text-align: center;
      }
    }
  }
}

@media(max-width: 1000px){
  .aboutUs{
    .list{
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
  }
}