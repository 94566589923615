.container{
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 30px;
}



.rodoIndex {
  padding-top: 150px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  h2{
    text-align: center;
    font-size: 30px;
    margin: 20px 0;
  }
  strong{
    font-weight: bold;
  }
  .topNav{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .link{
      display: flex;
      justify-content: center;
      width: 50%;
      font-size: 20px;
      color: #000;

    }
  }
}