.container{
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 30px;
}

.privacyPolicy{
  padding-top: 150px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  .titleWrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .title{
      padding-bottom: 5px;
      font-size: 36px;
      border-bottom: 4px solid #f63f3f;
      text-align: center;
    }
  }

}

.privacyPolicy p, li{
  font-weight: normal;
  letter-spacing: 0.6px;
  line-height: 20px;
}
.privacyPolicy li{
  list-style: disc;
  margin: 4px 0 4px 26px;
}
.privacyPolicy h5, p{
  margin: 12px 0;
}
.privacyPolicy h4{
  font-size: 26px;
}
.privacyPolicy strong{
  font-weight: bold;
}
.privacyPolicy{
  margin-top: 80px;
  .contactTitleWrapper{
    display: flex;
    justify-content: center;
    .contactTitle{
      font-size: 38px;
      font-weight: 700;
      border-bottom: 5px solid red;
      padding: 0 0 15px 0;
    }
  }
}
