.summary{
  .summaryTitle{
    margin-bottom: 32px;
    font-size: 22px;
    text-align: center;
  }
  .summaryBoxWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    .summaryBox{
      border: 2px solid #f63f3f;
      justify-content: space-between;
      margin-right: 20px;
      width: 100%;
      padding: 24px 12px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .top{
        width: 100%;
        display: flex;
        flex-direction: column;
        .summaryBoxTitle{
          text-align: center;
          font-size: 22px;
          margin-bottom: 4px;
        }
        .element{
          margin-top: 8px;
          span{
            font-weight: bold;
          }
        }
      }
      .backButtonWrapper{
        display: flex;
        justify-content: center;
        width: 100%;
        .backButton{
          margin-top: 16px;
          border: none;
          background-color: #f63f3f;
          font-size: 14px;
          padding: 8px 24px;
          border-radius: 16px;
          color: #fff;
          transition: 0.3s;
          cursor: pointer;
          &:hover{
            background-color: #f86666;
          }
        }
      }
    }
  }
  .sendInformation{
    font-size: 14px;
  }
  .sendFormWrapper{
    display: flex;
    justify-content: center;
    margin-top: 28px;
    .sendForm{
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 6px solid #f63f3f;
      padding: 12px 25px;
      font-size: 24px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      .icon{
        margin-left: 10px;
      }
      &:after{
        transition: 0.4s;
        background-color: #f63f3f;
        position: absolute;
        content: '';
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      &:hover{
        &:after {
          left: 0;
        }
      }
    }
  }
}

@media(max-width: 1200px){
  .summary{
    .summaryBoxWrapper{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}