.container {
  max-width: 1276px;
  margin: 0 auto;
}
.contactInformation{
  display: flex;
  flex-direction: column;

  .contactLink{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    background-color: #f63f3f;
    border: 2px solid transparent;
    border-radius: 20px;
    padding: 20px 0;
    color: #fff;
    text-decoration: none;
    transition: 0.4s;
    &:hover{
      background-color: transparent;
      border: 2px solid #f63f3f;
      color: #f63f3f;
    }
  }
  .titleWrapper{
    display: flex;
    justify-content: center;
    .title{
      padding-bottom: 5px;
      font-size: 36px;
      border-bottom: 4px solid #f63f3f;
      text-align: center;
    }
    .homepage{
      font-size: 42px;
    }
  }
  .or{
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    z-index: 2;
    display: flex;
    justify-content: center;
    .text{
      z-index: 2;
      font-size: 30px;
      background-color: #fff;
    }
    .line{
      position: absolute;
      display: block;
      content: '';
      background-color: #f63f3f;
      width: 100%;
      bottom: 44%;
      height: 2px;
      transform: translateY(100%);
    }
  }
  .content{
    .titleWrapper{
      display: flex;
      justify-content: center;
      .title{
        padding-bottom: 5px;
        font-size: 36px;
        border-bottom: 4px solid #f63f3f;
        text-align: center;
      }
      .homepage{
        font-size: 42px;
      }
    }
    .list{
      gap: 40px;
      margin: 38px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        padding: 25px 0;
        border-radius: 16px;
        box-shadow: 0 0 10px #000;
        .icon{
          color: #f63f3f;
          font-size: 50px;
          margin-bottom: 15px;
        }
        .linkWrapper{
          display: flex;
          .text{
            font-size: 18px;
            color: #fff;
            margin-right: 7px;
          }
          .link{
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            transition: 0.4s;
            &:hover{
              color: #f63f3f;
            }
          }
        }
      }
    }
  }
}
.home{
  padding: 0 25px;
}
@media(max-width: 700px){
  .contactInformation{
    .content{
      .list{
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
