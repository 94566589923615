.container{
  max-width: 1276px;
  margin: 0 auto;
  padding: 0 30px;
}

.whyUs{
  margin: 80px 0;
  .whyUsTitleWrapper{
    display: flex;
    justify-content: center;
    .whyUsTitle{
      padding-bottom: 5px;
      font-size: 42px;
      border-bottom: 4px solid #f63f3f;
    }
  }
  .benefits{
    margin-top: 40px;
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    color: #fff;
    .benefit{
      padding: 25px 30px;
      background-color: #000;
      border-radius: 8px;
      box-shadow: 0 0 10px #000;
      .titleWrapper{
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
        .icon{
          font-size: 40px;
          margin-bottom: 4px;
        }
        .title{
          font-size: 28px;
          padding-bottom: 8px;
          text-align: center;
          border-bottom: 2px solid #f63f3f;
        }
      }
      .description{
        text-align: center;
        margin-top: 12px;
        font-weight: 300;
        line-height: 18px;
        font-size: 16px;
      }
    }
  }
}

@media(max-width: 1000px){
  .whyUs{
    .benefits{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}